import CustomDatePicker from '@/components/datePicker';
import EmptyState from '@/components/EmptyState';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { dateToTimeStamp, formatDate } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockedVisitorSlice, {
  blockedDetailSelector,
  titleBtnDatePickerBlockedDetailSelector,
} from '@/redux/slice/blockedVisitor.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import {
  Card,
  Icon,
  IndexFilters,
  IndexFiltersProps,
  IndexTable,
  Pagination,
  SkeletonBodyText,
  useSetIndexFiltersMode,
  Scrollable,
  Tag,
  Link,
} from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { VisitorsDetailStyled } from '../styled';
import { useTranslation } from 'react-i18next';

const resourceName = {
  singular: 'visitor',
  plural: 'visitors',
};

const BlockedHistoryDetail = () => {
  const { t } = useTranslation(['common', 'visitor_analytics']);
  const { userPlanFree } = userPlans();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode, setMode } = useSetIndexFiltersMode();

  const blockedDetailTable = useSelector(blockedDetailSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const allAccess = useSelector(allAccessSelector);
  const titleBtn = useSelector(titleBtnDatePickerBlockedDetailSelector);

  const { data, isFetching } = apiCaller.useFetchBlockedDetailQuery(
    {
      ...blockedDetailTable,
      startDate: dateToTimeStamp(allAccess.startDate),
      endDate: dateToTimeStamp(allAccess.endDate),
      ipAddress: id || '',
    },
    { skip: isSkip },
  );

  const sortOptions = [
    { label: t('visitor_analytics:blocked_time'), value: 'accessAt asc', directionLabel: t('common:ascending') },
    { label: t('visitor_analytics:blocked_time'), value: 'accessAt desc', directionLabel: t('common:descending') },
  ];

  const items = useMemo(() => {
    return data?.listIp.map((blockedItem) => {
      return {
        accessLog: formatDate(blockedItem.accessAt),
        referringUrl: blockedItem.referringUrl,
        visitedUrls: blockedItem?.visitedUrls?.length ? (
          <Scrollable
            shadow
            style={{ height: '50px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: '5px' }}
          >
            {blockedItem.visitedUrls?.map((url, index) => (
              <Tag key={index}>
                <Link url={url} target="_blank">
                  URL {index + 1}
                </Link>
              </Tag>
            ))}
          </Scrollable>
        ) : null,
        device: blockedItem.device === Enum.DeviceType.DESKTOP ? <Icon source={DesktopIcon} /> : <Icon source={MobileIcon} />,
        browser: blockedItem.browserName,
        reason: blockedItem.reason,
        botName: blockedItem.botName || '---',
      };
    });
  }, [data?.listIp]);

  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(slice.blockedVisitorSlice.actions.handleBlockedDetail({ ...blockedDetailTable, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.blockedVisitorSlice.actions.handleTitleBtnDatePickerBlockedDetail(title));
  };

  const rowMarkup = useMemo(() => {
    return items?.map(({ accessLog, visitedUrls, referringUrl, browser, device, reason, botName }, index) => (
      <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell>{accessLog}</IndexTable.Cell>
        <IndexTable.Cell className="w-300">{referringUrl}</IndexTable.Cell>
        <IndexTable.Cell className="w-300">{visitedUrls}</IndexTable.Cell>
        <IndexTable.Cell>{device}</IndexTable.Cell>
        <IndexTable.Cell>{browser}</IndexTable.Cell>
        <IndexTable.Cell>{reason}</IndexTable.Cell>
        <IndexTable.Cell>{botName}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <VisitorsDetailStyled>
      <Layout
        layoutProps={{
          backAction: {
            onAction: () => {
              navigate(PATH.ANALYTICS);
              dispatch(
                blockedVisitorSlice.actions.handleBlockedDetail({
                  ...blockedDetailTable,
                  page: 1,
                }),
              );
            },
          },
          title: id,
          secondaryActions: (
            <CustomDatePicker
              titleButton={titleBtn}
              setTitleButton={onSaveTitleBtnDatePicker}
              startDate={blockedDetailTable.startDate}
              endDate={blockedDetailTable.endDate}
              onSave={onSaveDatePicker}
              isShowSelectedTime={true}
              disabled={userPlanFree}
              conditions={true}
            />
          ),
        }}
      >
        <Card>
          <IndexFilters
            sortSelected={[`${blockedDetailTable.sortBy} ${blockedDetailTable.sort}`]}
            sortOptions={sortOptions as IndexFiltersProps['sortOptions']}
            onSort={(value: string[]) => {
              const data = value[0].split(' ');
              dispatch(
                slice.blockedVisitorSlice.actions.handleBlockedDetail({
                  ...blockedDetailTable,
                  sortBy: data[0],
                  sort: data[1],
                }),
              );
            }}
            cancelAction={{
              onAction: () => { },
            }}
            tabs={[{ content: t('visitor_analytics:blocked_detail'), id: 'blocked detail' }]}
            selected={0}
            onSelect={() => { }}
            mode={mode}
            setMode={setMode}
            canCreateNewView={false}
            hideFilters
            hideQueryField
            onQueryChange={() => { }}
            onQueryClear={() => { }}
            filters={[]}
            onClearAll={() => { }}
          />
          <IndexTable
            resourceName={resourceName}
            itemCount={items?.length || 0}
            emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
            headings={[
              {
                title: <RegularText>{t('visitor_analytics:blocked_time')}</RegularText>,
                id: '5',
              },
              {
                title: <RegularText>{t('visitor_analytics:referring_url')}</RegularText>,
                id: '6',
              },
              {
                title: <RegularText>{t('visitor_analytics:visited_urls')}</RegularText>,
                id: '7',
              },
              { title: <RegularText>{t('visitor_analytics:device')}</RegularText>, id: '2' },
              { title: <RegularText>{t('visitor_analytics:browser')}</RegularText>, id: '3' },
              { title: <RegularText>{t('visitor_analytics:access_denied_reason')}</RegularText>, id: '4' },
              { title: <RegularText>{t('visitor_analytics:bot_name')}</RegularText>, id: '5' },
            ]}
            selectable={false}
          >
            {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
          </IndexTable>
          <div className="mt-16">
            {data && data.meta?.totalItems > 0 ? (
              <Pagination
                label={
                  data.meta?.totalItems || 0
                    ? t('common:showing_access_times', {
                      from: (blockedDetailTable.page - 1) * Number(blockedDetailTable.perPage) + 1,
                      to: Math.min(blockedDetailTable.page * Number(blockedDetailTable.perPage), data?.meta?.totalItems || 0),
                      total: data?.meta?.totalItems || 0,
                    })
                    : null
                }
                hasPrevious={(data?.meta?.currentPage || 0) > 1 && !isFetching}
                onPrevious={() => {
                  dispatch(
                    slice.blockedVisitorSlice.actions.handleBlockedDetail({
                      ...blockedDetailTable,
                      page: blockedDetailTable.page - 1,
                    }),
                  );
                }}
                hasNext={
                  (data?.meta?.currentPage || 0) <
                  Math.ceil((data?.meta?.totalItems ?? 0) / Number(blockedDetailTable.perPage)) && !isFetching
                }
                onNext={() => {
                  dispatch(
                    slice.blockedVisitorSlice.actions.handleBlockedDetail({
                      ...blockedDetailTable,
                      page: blockedDetailTable.page + 1,
                    }),
                  );
                }}
              />
            ) : null}
          </div>
        </Card>
      </Layout>
    </VisitorsDetailStyled>
  );
};

export default memo(BlockedHistoryDetail);
